.PixelPerfect{
    background-color: #61dafb;
    height: 100vh;
    display:flex;
    justify-content: top;
    align-items: center;
    flex-direction: column;
}

.ppOriginalImage{
    max-width: 100%;
    max-height: 100%;
}

.ppOriginalImageBox{
    padding-top: 15px;
    max-width: 40vw;
    max-height: 40vh;
}

.hiddenCanvas{
    opacity: 0;
}


@media (orientation:portrait), (max-width: 715px){
    .ppOriginalImageBox{
        padding-top: 15px;
        max-width: 70vw;
        max-height: 70vh;
    }
}
