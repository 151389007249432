.RasterCaster {
    margin: 0 2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rasterCasterBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#rasterCasterCanvas {
    width: 80vw;
    height: 80vh;
    border: solid 1px black;
}

.rasterCasterControls {
    display: grid;
    box-sizing: border-box;
    width: 80vw;
    grid-template-columns: min-content auto;
    grid-gap: 5px;
    justify-items: start;
    background-color: rgba(230, 230, 230, 0.8);
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;
}

.rasterCasterButtonBox{
    display: flex;
    flex-direction: column;
    text-align: left;
}

#input-function{
    width: 100%;
    height: 25vh;
    resize: vertical;
    overflow-x: scroll;
    white-space: nowrap;
}

.rasterCasterButtonLabel{
    display: flex;
    flex-wrap: nowrap;
    padding-right: 3px;
    white-space: nowrap;
    align-items: center;
}

input[type="radio" i] {
    margin: 5px;
}

.rasterCasterDisclaimer{
    display: none;
}

@media(max-width:400px), (orientation:portrait), (pointer:none), (pointer:coarse){
    .rasterCasterDisclaimer{
        display: block;
    }

    .rasterCasterHidden{
        display: none;
    }
}
