.PixelPerfectChoose{
    width: 80vw;
    height: 80vh;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .95;
    border-radius: 2%;    
    border: solid 2px #282c34;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.ppUpload{
    width: 95%;
    height:19%;
    border: 2px dashed #282c34;
    border-radius: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ppChooseImg{
    width: 100%;
    display: block;
    margin: 0;
} 

.ppChooseImg:hover{
    cursor: pointer;
    opacity: .90;
}

.ppImages{
    width: 95%;
    height: 70%;
    overflow-y: scroll;
    overflow-x:hidden;
    border: 2px solid #282c34;
    display: flex;
}

.masonryBox{
    column-count: 1;
}

.ppChooseClose{
    margin: 0;
}

.ppChooseClose:hover{
    cursor: pointer;
    text-decoration: underline;
}