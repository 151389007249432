.TicTacToeSingleplayer{
    background-color: #61dafb;
    height: 100vh;
    display:flex;
    align-items: center;
    flex-direction: column;
}

.tttButton{
    margin: 5px;
}