.chessTable{
    width: 75vh;
    height: 75vh;
    border: 3px black solid;
    display: flex;
    flex-flow: row wrap;
}





.chessBoardTile{
    box-sizing: border-box;
    width: 12.5%;
    height: 12.5%;
    border: .25px black solid;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(161,217,239);
}

.chessBoardTile:nth-child(2n+1) + .evenChessRow, .chessBoardTile:nth-child(2n) + .oddChessRow{
    background-color: rgb(32,146,191);
}

.chessSquare{
    display: flex;
    width: 80%;
    height: 80%;
    justify-content: center;
    align-items: center;
}

.chessLastMove{
    background-color: rgba(255, 255, 0, .3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chessPiece{
    font-size: 6vh;
    cursor: default;
}

.chessSquareAvailable{
    border: 1px red;
    border-radius: 100px;
    border-style: solid;
    cursor: pointer;
}

.chessSelectable:hover{
    border: 1px black solid;
    border-radius: 5%;
    cursor: pointer;
}

.chessSelectable:hover > .chessPiece{
    cursor: pointer;
}

@media (orientation:portrait){
    .chessTable{
        width: 80vw;
        height: 80vw;
    }

    .chessPiece{
        font-size: 7vw;
    }
}