Link{
    text-decoration: none;
    color: black;
}

.projectTile{
    display: flex;
    height: 33.333vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.projectTileTitle{
    margin: 0;
    color: black;
}

.projectTileText{
    display:none;
    color: black;
    padding: 0 2em;
}

.projectTile:hover > .projectTileTitle{
    display:none;
}

.projectTile:hover > .projectTileText{
    display:block;
}

/* Color Theme from https://www.w3schools.com/w3css/tryit.asp?filename=tryw3css_theme_cyan*/

/* .rasterCasterTile{
    background-color: rgb(0,132,150);
}

.mazeSolverTile{
    background-color:rgb(137,241,255);
}

.sortingAlgorithmsTile{
    background-color:rgb(0,170,193);
} */

a:nth-child(odd) > .projectTile, .Projects > .projectTile:nth-child(odd){
    background-color: rgb(27,122,159);
}