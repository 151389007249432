.aboutIntro{
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.aboutWordStyle{
    margin: 0;
    padding: 5px;
    font-family: "Bowlby One SC"; 
    font-size: 10vh; 
    font-style: normal; 
    font-variant: normal; 
    font-weight: 700; 
    color: #282c34;
}

.transitionWord{
    display: block;
}

.aboutGridContainer{
    display: grid;
    height: 18vh;
    gap: 1px;
    grid-template-columns: repeat(20, 1fr);
}

.gridSquare{
    width: 2vh;
    height: 2vh;
    background-color: #282c34;
}

.targetSquare{
    background-color: #5f687a;
}

.aboutSection{
    display: flex;
    flex-direction: row;
    width: 80%;
    padding: 0 10%;
    justify-content: center;
    align-items: center;
}

.aboutImageContainer{
    width: 40%;
}

.jmuImage{
    width: 100%;
    height: auto;
}

.aboutContainer{
    width: 60%;
    box-sizing: border-box;
    padding: 0 15px;
}

.aboutSubheader{
    text-align: left;
    font-size: 2em;
    margin-block-start: 0;
    margin-block-end: .5em;
}

.aboutDescription{
    text-align: left;
}

.aboutLink{
    text-decoration: underline;
    color:black;
}

.aboutLink:link, .aboutLink:visited, .aboutLink:active{
    text-decoration: underline;
    color:black;
}

.aboutLink:hover{
    color:#ee61fb;
}

.aboutSpace{
    width: 40%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes rotate {
    0% {background-position: 0 0;}
    100% {background-position: 500px 0;}
}

.aboutEarth {
    position: relative;
    width: 250px;
    height: 250px;
    margin: 3em auto;
    transform: rotateZ(23.5deg);
    transition: transform 200ms linear;
    animation: rotate 8s linear infinite;
    color: #000;
    border-radius: 50%;
    box-shadow: inset 20px 0 80px 6px rgba(0, 0, 0, 1);
    transform-style: preserve-3d;
}

.aboutEarth:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: 50%;
    box-shadow: -80px 15px 80px 10px rgba(0,0,0,.9) inset;
}

.aboutEarth:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: .2;
    border-radius: 50%;
    background: radial-gradient(circle at 100px 100px, #fff, #000);
}

@media (orientation:portrait), (max-width: 715px){
    .aboutIntro{
        flex-direction:column;
        align-items: flex-end;
    }
    

    .aboutWordStyle,.transitionWord{
        margin-right:5%;
    }

    .aboutSection{
        flex-direction: column;
    }

    .section1, .section3{
        flex-direction: column-reverse;
    }

    .aboutContainer{
        width: 100%;
    }

    .aboutImageContainer{
        width: 100%;
    }
    
    .aboutSpace{
        width: 100%;
    }
}












/* Old CSS for flipable

.aboutCards{
    position: fixed;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    width: 30vw;
    height: 80vh;
    
}

.flipable {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 3s;
    
    transform-style: preserve-3d;
    box-sizing: border-box;
}

.introAndEdu{
    backface-visibility: hidden;
}

.left > .aboutIntroCard{
    display: none;
}
/**********Intro Page*************
.aboutIntroCard{
    background-color:rgb(224, 168, 223);
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.aboutIntroCard::before {
    content: '';
    position: absolute;
    --spacer: 1em;
    top: var(--spacer);
    bottom: var(--spacer);
    left: var(--spacer);
    right: var(--spacer);
    border: 3px solid currentColor;
    transform: translateZ(2em);
}

.aboutCardTitle{
    font-size: 3.5rem;
    transform: translateZ(2em);
    text-transform: uppercase;
    margin: 0;
}

.aboutCardSubtitle{
    transform: translateZ(8em);
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: .75rem;
    font-weight: bold;
    opacity: .7;
}

/**********Education Page*************

.aboutEducationCard{
    background-color: #282c34;
    color: #b7c9e5;
    backface-visibility: hidden;
    transform: rotateY(.5turn);
    overflow-y: hidden;
}

/**********Credits Page*************

.aboutCreditsCard{
    background-color: #282c34;
    color: #b7c9e5;
    backface-visibility: hidden;
    transform: rotateY(.5turn);
    overflow-y: hidden;
}

.aboutCreditsListBox{
    overflow-y: scroll;
    height: 80%;
}


.aboutCreditsListBox::-webkit-scrollbar {
    width: 0px;
}
  

.creditsList{
    text-align: left;
    margin: 16px;
}

.creditsLink{
    color: #b7c9e5;
    text-decoration: underline;
}

.creditsLink:hover{
    color: #ee61fb;
}

/* .aboutCardSmallTitle{
    
    transform: translateZ(2rem);
} *


.left{
    transform: rotateY(-.5turn);
    
}
.front{
    transform: rotateY(0turn);
}

.aboutCardVerySmallTitle{
    font-size: 1rem;
    text-align: left;
    margin-left: 16px;
    text-decoration: underline;
}

/* .aboutCards:hover > .aboutIntroCard {
    transform: rotateY(-.5turn);
}

.aboutCards:hover > .aboutCreditsCard {
    transform: rotateY(0turn);
} */

/* .left {
    transform: rotateY(-.5turn);
}

.active {
    transform: rotateY(0turn);
}

.right{
    transform: rotateY(.5turn);
} *

.bigSpacer{
    height: 75vh;
}

.mediumSpacer{
    height: 50vh;
}



@media (orientation:portrait), (max-width: 715px){
    .aboutCards{
        width: 80vw;
        height: 70vh;
    }

    .aboutCardTitle{
        font-size: 2.5rem;
    }
  } */