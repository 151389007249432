html{
  background: #61dafb;
}

.App {
  text-align: center;
  display: inline-block;
  padding-left: 60px;
  width: 100%;
  background: #61dafb;
  box-sizing: border-box;
  transition-duration: 1s;
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.descriptionTitle{
  margin-bottom: 0;
}

.descriptionText{
  width: 50%;
  align-self: center;
  text-align: left;
  padding-bottom: 1rem;
}



.Hidden {
  display:none;
}

input, button{
  color:black
}

@media (pointer:none), (pointer:coarse){
  .App {
    padding-left: env(safe-area-inset-left);
  }
}

@media (orientation:portrait), (max-width: 715px){
  .App {
    padding-left: 0;
  }

  .Shifted{
    transition-duration: 1s;
    transform: translateX(100vw);
  }

  .descriptionText{
    width: 80%;
  }
}