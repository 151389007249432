.MazeSolver{
    background-color: #61dafb;
    display:flex;
    justify-content: top;
    align-items: center;
    flex-direction: column;
}

.msBoard{
    display: flex;
}

.mazeSquare{
    height: 20px;
    width: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.msPast{
    border-radius: 50%;
    border: #282c34 1px solid;
    width: 12px;
    height: 12px;
}

.msDot{
    border-radius: 50%;
    background-color: #282c34;
    width: 12px;
    height: 12px;
}

.msHalfDot{
    border-radius: 50%;
    background-color: #282c34;
    opacity: .5;
    width: 12px;
    height: 12px;
}

.msBorderTop{
    border-top:solid 1px;
}

.msBorderRight{
    border-right:solid 1px;
}

.msBorderBottom{
    border-bottom:solid 1px;
}

.msBorderLeft{
    border-left:solid 1px;
}

.msStart{
    background-color: green;
    cursor: pointer;
}

.msEnd{
    background-color: red;
    cursor: pointer;
}

.msStartSquare{
    position: absolute;
    transform:translate(-50%,-50%);
    top: -40px;
    left: -40px;
    height: 20px;
    width: 20px;
    background-color: green;
    cursor: grabbing;
    pointer-events: none;
}

.msEndSquare{
    position: absolute;
    transform:translate(-50%,-50%);
    top: -40px;
    left: -40px;
    height: 20px;
    width: 20px;
    background-color: red;
    cursor: grabbing;
    pointer-events: none;
}