.TicTacToeMenu{
    background-color: #61dafb;
}

.tttModeSelectBox{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center; 
    align-items: center;
    overflow: auto;
    color: #282c34;
}

.tttNavBox{
    display: inline-block;
    box-sizing: border-box;
    margin: 0 96px;
}

.navIcon{
    position: relative;
    top: 0;
    transition: top ease 0.5s
}

.tttNavBox:hover .navIcon{
    top: -10px;
}

.tttNavBox:hover .navItem{
    text-decoration: underline;
}

.tttNavBox:hover {
    cursor: pointer;
}

.navItem{
    margin: 0;
    color: #282c34;
    font-weight: bold;
}

@media (max-width:715px){
    .tttModeSelectBox{
        flex-direction: column;
    }

    .tttNavBox{
        margin: 15px 0;
    }
}