.ChessMultiplayer{
    background-color: #61dafb;
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chessTitle{
    margin: 0;
    padding: 0;
}

.chessButton{
    margin: 5px;
}

@media (orientation:portrait){
    .ChessMultiplayer{
        justify-content: flex-start;
    }

    .chessTitle{
        padding-top: 2.5vh;
    }
}