.Home{
    background-color: #61dafb;
    height: 100vh;
}

.nameBox{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center; 
    align-items: center;
    color: #282c34;
}

.ml13 {
    margin: 0;
    font-size: 3em;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-weight: 600;
    color: #282c34;
}
  
.ml13 .letter {
    display: inline-block;
    line-height: 1em;
    color: #282c34;
}

.navSection{
    position: fixed;
    bottom: 5%;
    left:50%;
    transform: translate(-50%);
}

.navBox{
    display: inline-block;
    box-sizing: border-box;
    margin: 0 32px;
}

.navIcon{
    position: relative;
    top: 0;
    transition: top ease 0.5s
}

.navBox:hover .navIcon{
    top: -10px;
}

.navBox:hover .navItem{
    text-decoration: underline;
}

.navBox:hover {
    cursor: pointer;
}

.navItem{
    margin: 0;
    color: #282c34;
    font-weight: bold;
}

.homeNameBreak{
    display: none;
}

@media (max-width: 715px){
    .homeNameBreak{
        display: block;
    }
}

@media (orientation:portrait){
    .navBox{
        margin: 0;
    }

    .nameBox{
        height: 70vh;
    }

    

    .navSection{
        display: flex;
        justify-content: space-evenly;
        width: 80vw;
    }

    
}