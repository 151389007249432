.HangmanDrawing{
    border:1px solid black;
    text-align: center;
    width: 17em;
    height: 17em;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.hangmanDrawingXMP{
    display: inline-block; 
    font-weight: bold; 
    font-family: courier new; 
    text-align: left;
    margin: 1em;
}