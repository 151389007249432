.ChessSingleplayer{
    background-color: #61dafb;
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chessButton{
    margin: 5px;
}

@media (orientation:portrait){
    .ChessSingleplayer{
        justify-content: flex-start;
    }
}