Link{
    text-decoration: none;
    color: black;
}

.gameTile{
    display: flex;
    height: 33.333vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.gameTileTitle{
    margin: 0;
    color: black;
}

.gameTileText{
    display:none;
    color: black;
    padding: 0 2em;
}

.gameTile:hover > .gameTileTitle{
    display:none;
}

.gameTile:hover > .gameTileText{
    display:block;
}

/* Color Theme from https://www.w3schools.com/w3css/tryit.asp?filename=tryw3css_theme_light-blue*/



a:nth-child(odd) > .gameTile, .Games > .gameTile:nth-child(odd){
    background-color: rgb(27,122,159);
}